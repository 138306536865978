<template>
	<div>
		<!-- <BaseEmpty width="97"> -->
		<BaseEmpty>
			<template>
				<div class="filter-wrap mb-1">
					<div
						v-if="possuiPermissao('GER_I_AGENDAMENTOS')"
						class="filter mr-2 icon-add-transaction"
						@click="showSchedule({})"
						title="Agendar/Lançar Pagamento"
					>
						<FilePlusIcon/>
					</div>
					<div
						v-if="possuiPermissao('GER_P_AGENDAMENTOS')"
						class="filter mr-2 icon-payment"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="showPayment('All')"
						title="Baixar Selecionados"
					>
						<CreditCardIcon toolTip="Baixar selecionados"/>
					</div>
					<div
						v-if="possuiPermissao('GER_C_AGENDAMENTOS')"
						class="filter mr-2 icon-cancel"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="confirmaCancel('All')"
						title="Cancelar Selecionados"
					>
						<XCircleIcon/>
					</div>
					<div
						v-if="possuiPermissao('GER_D_AGENDAMENTOS')"
						class="filter mr-2 icon-delete"
						v-bind:class="!itemSelected ? 'filter-disable' : ''"
						@click="confirmaDelete('All')"
						title="Apagar Selecionados"
					>
						<Trash2Icon/>
					</div>
					<div
						class="filter"
						v-bind:class="[showFiltersForm ? 'filter-active' : '']"
						@click="filterDropDownActive"
						title="Alterar Filtros"
					>
						<FilterIcon/>
					</div>
				</div>
				<b-modal
					ref="modalSchedule"
					hide-footer
					:title="titleModalSchedule"
					size="md"
					no-overflow
					hide-no-focus="true"
				>
					<div class="modal-edicao modal-edicao-label">
						<b-row class="filters-orientation">
							<b-col class="mb-3 text-parcela">
								<label class="label-conta">Data Lançamento</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder=""
										id="data-lancamento"
										input-class="bg-white"
										v-model="schedulePaymentUpsert_date"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Lançamento"
										@input="validateAccountClass(schedulePaymentUpsert_idAccountSchedule)"
									/>
								</div>
							</b-col>
							<b-col class="text-parcela">
								<label class="label-conta">Data Pagamento</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder=""
										id="data-pagamento"
										input-class="bg-white"
										v-model="schedulePaymentUpsert_paymentDate"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Pagamento"
										:disabled="permiteParcelas"
									/>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-2">
							<b-col cols="6" class="text-parcela">
								<span class="label-conta">
									Conta
								</span>
								<div>
									<b-form-select
										class="modal-edicao-input"
										v-model="schedulePaymentUpsert_idAccountSchedule"
										name="accountSchedule"
										:options="optionModalAccount"
										:disabled="loading"
										v-validate="{ required: true }"
										:state="validateState('accountSchedule')"
										@input="validateAccountClass(schedulePaymentUpsert_idAccountSchedule)"
										@change="getAccountPayBalance(schedulePaymentUpsert_idAccountSchedule)"
									>
										<template slot="first">
											<option :value="null">
												Selecione
											</option>
										</template>
									</b-form-select>
								</div>
							</b-col>
							<b-col cols="6" class="text-parcela">
								<span  class="label-conta">
									Categoria da Transação
								</span>
								<div>
									<b-form-select
										class="modal-edicao-input"
										v-model="schedulePaymentUpsert_idCategoryExpense"
										name="categoryExpense"
										:options="optionModalCategoryExpense"
										:disabled="loading"
										v-validate="{ required: true }"
										:state="validateState('categoryExpense')"
									>
										<template slot="first">
											<option :value="null">
												Selecione
											</option>
										</template>
									</b-form-select>
								</div>
							</b-col>
						</b-row>
						<b-row class="mt-3">
							<b-col cols="6" class="d-block text-parcela">
								<span class="label-conta">Valor</span>
								<b-form-input
									name="valor"
									class="modal-edicao-input number-class"
									v-model="schedulePaymentUpsert_valor_string"
									autocomplete="off"
									placeholder=""
									inputmode="numeric"
									v-validate="'required|noZeroCurrency'"
									:state="validateState('valor')"
									@input="setInstallments(schedulePaymentUpsert_valor_string)"
								/>
							</b-col>
							<b-col v-if="!permiteParcelas || editing" cols="6" class="d-block text-parcela">
								<span class="label-conta">Balance</span>
								<b-form-input
									name="accountPayBalance"
									class="modal-edicao-input number-class"
									v-model="accountPayBalance"
									:disabled="true"
								/>
							</b-col>
							<b-col v-else cols="6" class="d-block text-parcela">
								<span class="label-conta">Parcelas</span>
								<b-form-input
									name="installments"
									class="modal-edicao-input number-class"
									type="number"
									v-model="schedulePaymentUpsert_installments"
									autocomplete="off"
									placeholder=""
									min="1"
									v-validate="{ required: true, min_value: 1 }"
									:state="validateState('installments')"
									@input="setInstallments()"
									:disabled="!permiteParcelas || editing"
								/>
							</b-col>
						</b-row>
						<section
							v-if="parseInt(schedulePaymentUpsert_installments) > 1"
							class="label-table mt-3"
						>
							<div class="mt-2" style="padding: 3px 0;">
								<b-row
									class="ml-4 mr-5"
								>
									<b-col
										cols="3"
									>
										Parcela
									</b-col>
									<b-col
										cols="4"
									>
										Vencimento
									</b-col>
									<b-col
										cols="5"
									>
										Valor
									</b-col>
								</b-row>
							</div>
						</section>
						<section
							v-if="parseInt(schedulePaymentUpsert_installments) > 1"
							class="table-parcelas"
						>
							<b-row
								v-for="i in parseInt(schedulePaymentUpsert_installments)" :key="i"
								class="ml-2 mb-1 mr-5 justify-items"
							>
								<b-col
									cols="3" class="d-flex label-conta"
								>
									<span class="label-conta"># {{ i }}</span>
								</b-col>
								<b-col
									cols="4" class="d-flex label-conta"
								>
									<span class="label-conta">{{ dataPaymentsInstallments[i] }}</span>
								</b-col>
								<b-col
									cols="5" class="d-flex label-conta"
								>
									<b-form-input
										:name="`amount-${i}`"
										class="modal-edicao-input number-class"
										v-model="schedulePaymentUpsert_amount_string[i - 1]"
										autocomplete="off"
										placeholder=""
										:state="validateState(`amount-${i}`)"
										v-validate="'required|noZeroCurrency'"
										inputmode="numeric"
										@input="setNewValue(schedulePaymentUpsert_amount_string[i - 1], i - 1)"
									/>
								</b-col>
							</b-row>
						</section>
						<b-row class="mt-3 filters-orientation">
							<b-col class="d-block text-parcela mb-3">
								<span class="label-conta">Referência</span>
								<b-form-input
									name="reference"
									class="modal-edicao-input"
									v-model="schedulePaymentUpsert_reference"
									autocomplete="off"
									placeholder=""
									v-validate="{ required: false }"
									:state="validateState('reference')"
								/>
							</b-col>

							<b-col class="d-block text-parcela">
								<span class="label-conta">Observações</span>
								<b-form-input
									name="comments"
									class="modal-edicao-input"
									v-model="schedulePaymentUpsert_comments"
									autocomplete="off"
									placeholder=""
									v-validate="{ required: false }"
									:state="validateState('comments')"
								/>
							</b-col>
						</b-row>
					</div>
					<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalSchedule')">Cancelar</b-button>
					<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
					<b-button
						class="mt-3 ml-1 float-right btn-modal btn-salvar"
						@click="save"
						:disabled="invalidForm || insufficientBalance"
					>
						Salvar
					</b-button>
				</b-modal>

				<b-modal
					ref="modalPayment"
					hide-footer
					title="Pagamentos"
					size="lg"
					no-overflow
					hide-no-focus="true"
				>
					<div class="modal-edicao mb-3 modal-edicao-label">
						<b-row >
							<b-col cols="8" class="text-parcela">
								<label class="label-conta">Data Pagamento</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder=""
										id="paymentExecuteDate"
										input-class="bg-white"
										v-model="paymentExecuteDate"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="paymentExecuteDate"
									/>
								</div>
							</b-col>

							<b-col cols="4" class="d-block text-parcela">
								<span class="label-conta">Valor</span>
								<b-form-input
									name="paymentExecuteAmount"
									class="modal-edicao-input number-class"
									inputmode="numeric"
									v-model="paymentExecuteAmount_string"
									:disabled="true"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col cols="8" class="label-conta">
								<span>
									Conta
								</span>
								<div>
									<b-form-select
										class="modal-edicao-input"
										v-model="paymentExecuteAccount"
										name="paymentExecuteAccount"
										:options="optionModalAccount.filter(account => account.permitePagamento)"
										:disabled="loading"
										v-validate="{ required: !havePermissionMultiAccountPay }"
										:state="validateState('paymentExecuteAccount')"
										@input="validateAccountClass(paymentExecuteAccount)"
										@change="getAccountPayBalance(paymentExecuteAccount)"
									>
										<template slot="first">
											<option :value="null">
												Selecione
											</option>
										</template>
									</b-form-select>
								</div>
							</b-col>
							<b-col cols="4" class="d-flex">
								<section class="d-block text-parcela" style="width: 100%;">
									<span class="label-conta">Balance</span>
									<b-form-input
										name="accountPayBalance"
										class="modal-edicao-input number-class"
										v-model="accountPayBalance"
										:disabled="true"
									/>
								</section>
								<section
									class="section-btn-add-pay"
									v-if="havePermissionMultiAccountPay"
								>
									<b-button
										class="btn-pay green"
										@click="addMethotdPay(paymentExecuteAccount)"
										:disabled="insufficientBalanceAccount || balanceZero || !paymentExecuteAccount"
									>
										+
									</b-button>
								</section>
							</b-col>
						</b-row>
						<section
							v-if="methods_pay.length"
							class="table-parcelas mt-2"
						>
							<b-row
								v-for="i in methods_pay.length" :key="i"
								class="mt-1 mb-1 mr-0 justify-items"
							>
								<b-col
									cols="2" class="d-flex label-conta"
								>
									<span class="label-conta"># {{ i }}</span>
								</b-col>
								<b-col
									cols="5" class="d-flex label-conta"
								>
									<span class="label-conta">{{ methods_pay[i - 1].account.description }}</span>
								</b-col>
								<b-col
									cols="5" class="d-flex label-conta"
								>
									<b-form-input
										:name="`methods_pay-${i}-amount`"
										class="modal-edicao-input number-class"
										v-model="methods_pay[i - 1].amount_string"
										autocomplete="off"
										placeholder=""
										inputmode="numeric"
										:state="validateState(`methods_pay-${i}-amount`)"
										v-validate="'required|noZeroCurrency'"
										@input="setNewValueMethodPay(methods_pay[i - 1].amount_string, i - 1)"
									/>
									<div
										class="btn-pay red"
										title="Apagar"
										@click="delMethodPay(i - 1)"
									>
										<Trash2Icon size="24" alt="Apagar"/>
									</div>
								</b-col>
							</b-row>
						</section>
						<b-row class="mt-2">
							<b-col cols="12" class="d-block text-parcela">
								<span class="label-conta">Observações</span>
								<b-form-input
									name="paymentExecuteComments"
									class="modal-edicao-input"
									v-model="paymentExecuteComments"
									autocomplete="off"
									placeholder=""
									v-validate="{ required: false }"
									:state="validateState('paymentExecuteComments')"
								/>
							</b-col>
						</b-row>
					</div>
					<b-button class="ml-1 mt-3 float-left btn-modal btn-cancel" @click="fecharModal('modalPayment')">Cancelar</b-button>
					<b-button class="mt-3 float-left btn-modal btn-limpar" @click="limparModal()">Limpar</b-button>
					<b-button
						class="mt-3 ml-1 float-right btn-modal btn-salvar"
						@click="paymentExecute"
						:disabled="invalidForm || invalidFormMulti"
					>
						Salvar
					</b-button>
				</b-modal>

				<div v-if="showFiltersForm" class="filters-form content-card">
					<div>
						<b-row
							class="filters-orientation filters-section"
						>
							<b-col class="filter-item">
								<label>
									Estado do agendamento
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedStatusPaymentSchedule"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="selectStatusPaymentSchedule"
									data-cy="status"
									:btnLabel="() => (selectedStatusPaymentSchedule.length > 1 ? `${selectedStatusPaymentSchedule[0].nome}...` : selectedStatusPaymentSchedule[0] && selectedStatusPaymentSchedule[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Contas
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedAccount"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="selectAccount"
									data-cy="account"
									:btnLabel="() => (selectedAccount.length > 1 ? `${selectedAccount[0].nome}...` : selectedAccount[0] && selectedAccount[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Tipos de Contas
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedAccountClass"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="selectAccountClass"
									data-cy="accountClass"
									:btnLabel="() => (selectedAccountClass.length > 1 ? `${selectedAccountClass[0].nome}...` : selectedAccountClass[0] && selectedAccountClass[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Categoria das Transações
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedCategoryExpenses"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: true, labelName: 'nome' }"
									:selectOptions="selectCategoryExpenses"
									data-cy="categoryExpense"
									:btnLabel="() => (selectedCategoryExpenses.length > 1 ? `${selectedCategoryExpenses[0].nome}...` : selectedCategoryExpenses[0] && selectedCategoryExpenses[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Referência
								</label>
								<input
									id="reference"
									class="form-control time-pick"
									v-model="reference"
								/>
							</b-col>
						</b-row>
						<b-row
							class="filters-orientation filters-section-seconds"
						>
							<b-col class="filter-item">
								<label>Data inicial</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Início"
										id="data-inicial-historica"
										input-class="bg-white"
										v-model="inicio"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Inicial"
										@input="setFilterDateEnd(inicio)"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label>Data final</label>
								<div class="d-flex">
									<datepicker
										class="w-100"
										placeholder="Fim"
										id="data-final-historica"
										input-class="bg-white"
										v-model="fim"
										:format="formatter"
										:language="ptBR"
										:bootstrap-styling="true"
										data-cy="Data Final"
									/>
								</div>
							</b-col>
							<b-col class="filter-item">
								<label>
									Data da pesquisa
								</label>
								<vue-multi-select
									class="multi-100 mb-1 style-select"
									:disabled="loading"
									v-model="selectedDataPesquisa"
									search
									searchPlaceholder="Pesquisar"
									:options="{ multi: false, labelName: 'nome' }"
									:selectOptions="selectDataPesquisa"
									data-cy="status"
									:btnLabel="() => (selectedDataPesquisa.length > 1 ? `${selectedDataPesquisa[0].nome}...` : selectedDataPesquisa[0] && selectedDataPesquisa[0].nome) || 'Selecione'"
								/>
							</b-col>
							<b-col class="filter-item">
								<label>
									Observações
								</label>
								<input
									id="comments"
									class="form-control time-pick"
									v-model="comments"
								/>
							</b-col>
						</b-row>
						<div class="mt-4 search-button" @click="findPaymentSchedule(true)">
							<SearchIcon/>
						</div>
					</div>
				</div>
				<b-row class="row-1">
				</b-row>
			</template>
		</BaseEmpty>
		<Base style="margin-top: -10px">
			<template>
				<div v-if="linhas.length" class="head-row mb-2 width-pagination-items mt-1">
					<div class="text-head-row width-pagination">
						<label class="text-head-row ml-0">
							Resultado {{ pagination.offset + 1 }}-{{ pagination.offset + linhas.length }} de {{ pagination.count }}
						</label>
						<b-pagination
							class="mr-0 mb-2 ml-3"
							id="page"
							v-model="currentPage"
							:total-rows="pagination.count"
							:per-page="pagination.limit.value"
						></b-pagination>
					</div>
					<b-form
						class="text-head-row width-pagination">
						<label class="text-head-row ml-0 w-60">
							Itens por página
						</label>
						<b-form-select
							class="mr-0 mb-2"
							v-model="pagination.limit.value"
							name="itens-por-pagina"
							:options="optionsItensPorPag"
							@input="changeItensByPage()"
							:disabled="loading"
						/>
					</b-form>
				</div>
				<div v-if="linhas.length" class="table-responsive">
					<DataTable v-if="!isMobile"
						class="data-table"
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:nosearch="true"
						:nofilters="true"
						name="paymentSchedule"
						:hasPagination="true"
						:noedit="true"
						:acoes="acoes"
						:globalAcoes="false"
						:permissaoPayment="possuiPermissao('GER_P_AGENDAMENTOS')"
						:permissaoEdit="possuiPermissao('GER_U_AGENDAMENTOS')"
						:permissaoDelete="possuiPermissao('GER_D_AGENDAMENTOS')"
						:permissaoCancel="possuiPermissao('GER_C_AGENDAMENTOS')"
						:permissaoRecover="possuiPermissao('GER_C_AGENDAMENTOS')"
						@clickDelete="confirmaDelete"
						@clickEdit="showSchedule"
						@clickPayment="showPayment"
						@clickRecover="toRecover"
						@clickCancel="confirmaCancel"
						:selectItem="selectItem && possuiPermissao('GER_M_AGENDAMENTOS')"
						:alignColumns="alignColumnsTable"
						@clickSortTableBy="(v) => sortTableBy(v)"
						:state="{
							sortBy: sortTable.sortBy,
							sortAsc: sortTable.order === 1,
							query: ''
						}"
						:async="true"
						@clickCheck="showButonsTop"
					/>
					<CardTransaction v-else
						:loading="loading"
						:colunas="colunas"
						:linhas="linhas"
						:errMsg="errMsg"
						:acoes="acoes"
						:permissaoPayment="possuiPermissao('GER_P_AGENDAMENTOS')"
						:permissaoEdit="possuiPermissao('GER_U_AGENDAMENTOS')"
						:permissaoDelete="possuiPermissao('GER_D_AGENDAMENTOS')"
						:permissaoCancel="possuiPermissao('GER_C_AGENDAMENTOS')"
						:permissaoRecover="possuiPermissao('GER_C_AGENDAMENTOS')"
						:selectItem="selectItem && possuiPermissao('GER_M_AGENDAMENTOS')"
						@clickDelete="confirmaDelete"
						@clickEdit="showSchedule"
						@clickPayment="showPayment"
						@clickRecover="toRecover"
						@clickCancel="confirmaCancel"
						:globalAcoes="false"
						@clickCheck="showButonsTop"
						:colsDataCard="colsDataCard"
					/>
				</div>
				<div v-else class="alert alert-warning" role="alert">
					{{ errMsg }}
				</div>
			</template>
		</Base>
	</div>
</template>

<script>
	import dayjs from "dayjs";
	import "dayjs/locale/pt-br";
	import Datepicker from "vuejs-datepicker";
	import { ptBR } from "vuejs-datepicker/dist/locale";

	import BaseEmpty from "@/templates/BaseEmpty";
	import Base from "@/templates/Base.vue";
	import DataTable from "@/components/DataTable";
	import { getDateMonthEnd, numberFormatted, stringToNumber, stringToDateUTC, validateNumber, formatterSimple } from "@/helpers/common";
	import { AccountService } from "../../services/account";
	import { AccountClassService } from "../../services/accountClass";
	import { CategoryExpensesService } from "../../services/categoryExpenses";
	import { PaymentScheduleService } from "../../services/paymentSchedule";
	import { possuiPermissao } from "../../helpers/permissions";
	import { hasFieldsWithErrors } from "@/helpers/validators";

	import "./styles.css";
	import { CreditCardIcon } from "vue-feather-icons";
	import CardTransaction from "@/components/CardTransaction.vue";

	export default {
		components: {
			BaseEmpty,
			Base,
			DataTable,
			Datepicker,
			CardTransaction
		},

		inject: ["parentValidator"],

		data () {
			return {
				ready: true,
				loading: false,
				updating: false,
				ptBR,
				paymentSchedule: [],
				showFiltersForm: false,
				showAddModal: false,
				selectAccount: [],
				selectAccountClass: [],
				selectCategoryExpenses: [],
				selectPaymentSchedule: [],
				selectStatusPaymentSchedule: [
					{ nome: "Em aberto", value: 0 },
					{ nome: "Baixadas", value: 1 },
					{ nome: "Canceladas", value: 2 }
				],
				selectDataPesquisa: [
					{ nome: "Lançamento", value: 0},
					{ nome: "Vencimento", value: 1},
					{ nome: "Pagamento", value: 2}
				],

				// filters
				filters: {},
				inicio: null,
				fim: null,
				selectedAccount: [],
				selectedAccountClass: [],
				selectedCategoryExpenses: [],
				selectedStatusPaymentSchedule: [],
				comments: "",
				reference: "",
				selectedDataPesquisa: [{ nome: "Vencimento", value: 1 }],

				paymentScheduleSelecionada: {},

				titleModalSchedule: "",
				optionModalAccount: [],
				optionModalCategoryExpense: [],

				schedulePaymentUpsert_comments: "",
				schedulePaymentUpsert_reference: "",
				schedulePaymentUpsert_idCategoryExpense: null,
				schedulePaymentUpsert_idAccountSchedule: null,
				schedulePaymentUpsert_date: new Date(),
				schedulePaymentUpsert_amount: [],
				schedulePaymentUpsert_amount_string: [],
				schedulePaymentUpsert_paymentDate: new Date(),
				schedulePaymentUpsert_installments: 1,
				schedulePaymentUpsert_valor: 0,
				schedulePaymentUpsert_valor_string: "0,00",

				errMsg: "Nenhum agendamento cadastrado!",
				colunas: [
					"Referência",
					"Conta",
					"Categoria",
					"Estado",
					"Data",
					"Vence",
					"Valor",
					"Observações"
				],
				colsDataCard: {
					0: {
						idx: 1,
						label: "Conta",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					1: {
						idx: 2,
						label: "Categoria",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					2: {
						idx: 4,
						label: "Data",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					3: {
						idx: 5,
						label: "Vence",
						textAlign: "center",
						cols: 3,
						visible: true
					},
					4: {
						idx: 6,
						label: "Valor",
						textAlign: "right",
						cols: 3,
						visible: true
					},
					5: {
						idx: 3,
						label: "Status",
						textAlign: "left",
						cols: 3,
						visible: true
					},
					6: {
						idx: 0,
						label: "Referência",
						textAlign: "left",
						cols: 6,
						visible: true
					},
					7: {
						idx: 7,
						label: "Observações",
						textAlign: "left",
						cols: 6,
						visible: true
					}

				},
				linhas: [],
				pagination: {
					count: 0,
					page: 1,
					offset: 0,
					limit: {
						value: 25
					},
					pages: 1
				},
				currentPage: 1,
				optionsItensPorPag: [
					{
						value: 5,
						text: "5 Items"
					},
					{
						value: 25,
						text: "25 Items"
					},
					{
						value: 50,
						text: "50 Items"
					},
					{
						value: 75,
						text: "75 Items"
					},
					{
						value: 100,
						text: "100 Items"
					}
				],

				invalidForm: true,
				editing: false,
				permiteParcelas: null,
				permitePagamento: null,
				selectItem: false,
				acoes: false,

				paymentExecuteDate: new Date(),
				paymentExecuteAccount: null,
				paymentExecuteAmount: numberFormatted(0),
				paymentExecuteAmount_string: "0,00",

				paymentExecuteComments: "",
				paymentScheduleExecute: [],

				config: {
					timeout: 2000,
					showProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true
				},

				alignColumnsTable: [
					"left",
					"left",
					"left",
					"left",
					"left",
					"left",
					"right",
					"left"
				],

				accountService: new AccountService(),
				accountClassService: new AccountClassService(),
				categoryExpensesService: new CategoryExpensesService(),
				paymentScheduleService: new PaymentScheduleService(),
				possuiPermissao,
				sortTable: {
					sortBy: 5,
					order: -1
				},
				accountPayBalance: "0,00",
				insufficientBalance: false,
				dataPaymentsInstallments: [],
				bestShoppingDay: false,
				itemSelected: false,
				methods_pay: [],
				insufficientBalanceAccount: true,
				methodsPayAmount: 0,
				balanceZero: false,
				havePermissionMultiAccountPay: false,
				invalidFormMulti: true,
				isMobile: false
			};
		},

		watch: {
			currentPage () {
				this.changePage();
			},

			filters () {
				this.linhas = [];
			}
		},

		async mounted () {
			this.checkIfMobile();
			window.addEventListener("resize", this.checkIfMobile);

			const paramsUser = localStorage.params ? (JSON.parse(localStorage.params)) : {};
			this.bestShoppingDay = Boolean(paramsUser.config && paramsUser.config.bestShoppingDay);
			this.havePermissionMultiAccountPay = possuiPermissao("GER_P_MULTI_ACCOUNT");
			this.invalidFormMulti = this.havePermissionMultiAccountPay;

			await Promise.all([
				this.setFilter(),
				this.findAccount(),
				this.findAccountClass(),
				this.findCategoryExpenses()
			]);
			await this.findPaymentSchedule();
		},

		beforeDestroy () {
			window.removeEventListener("resize", this.checkIfMobile);
		},

		methods: {
			checkIfMobile	() {
				this.isMobile = window.innerWidth <= 768;
			},

			sortTableBy (v) {
				this.sortTable = v;
				if (this.sortTable.sortBy === 2)
					return;

				this.findPaymentSchedule();
			},

			addMonth (month, year) {
				if (month == 11) {
					this.schedulePaymentUpsert_paymentDate.setMonth(0);
					this.schedulePaymentUpsert_paymentDate.setFullYear(year + 1);
				} else {
					this.schedulePaymentUpsert_paymentDate.setMonth(month + 1);
				}
			},

			validateAccountClass (idAccount) {
				if (this.editing) return;

				const accountSelected = this.optionModalAccount.filter(a => a.value === idAccount);

				if (accountSelected.length) {
					this.permiteParcelas = accountSelected[0].permiteParcelas;
					this.permitePagamento = accountSelected[0].permitePagamento;

					const paymentDate = accountSelected[0].payDay || accountSelected[0].closingDay;
					let month = this.schedulePaymentUpsert_date.getMonth(); // obtem mes do lançamento
					const year = this.schedulePaymentUpsert_date.getFullYear(); // obtem ano do lançamento

					if (accountSelected[0].closingDay && !this.permitePagamento) {
						const accountClosed = this.getStateClosedAccount(
							this.schedulePaymentUpsert_date,
							accountSelected[0].closingDay,
							accountSelected[0].closeNextDayWeekend
						);
						// Caso dia de cierre es mayor que dia de pago
						// el sistema debe entender qie cierra en una mes y vence en el siguiente
						// Por tal motivo debe ser adicionado un mes mas para el agendamiento
						if (accountSelected[0].closingDay > accountSelected[0].payDay)
							month = month + 1;

						this.schedulePaymentUpsert_paymentDate = new Date(this.schedulePaymentUpsert_paymentDate.setDate(paymentDate));
						if (accountClosed) {
							this.addMonth(month, year);
							month = this.schedulePaymentUpsert_date.getMonth();
						} else {
							this.schedulePaymentUpsert_paymentDate.setMonth(month);
						}
					} else {
						this.schedulePaymentUpsert_paymentDate = new Date(this.schedulePaymentUpsert_date);
					}
				} else {
					this.permiteParcelas = null;
					this.permitePagamento = null;
					this.schedulePaymentUpsert_paymentDate = new Date(this.schedulePaymentUpsert_date);
				}

				if (!this.permiteParcelas || !this.schedulePaymentUpsert_installments) {
					this.schedulePaymentUpsert_installments = 1;
					this.schedulePaymentUpsert_amount = [ this.schedulePaymentUpsert_valor ];
				}

				if (this.permiteParcelas && this.schedulePaymentUpsert_installments > 1)
					this.setInstallments();

			},

			getStateClosedAccount (date_, closingDay, closeNextDayWeekend) {
				const date = new Date(date_);
				const month = date.getMonth(date_);
				let day = date.getDate(); // obtem dia do lançamento
				if (!this.bestShoppingDay) {
					date.setDate(date.getDate() - 1);
					day = date.getDate();
				}

				const dateClosing = new Date(this.schedulePaymentUpsert_date);
				const monthClosing = date.getMonth(dateClosing);

				if (day >= closingDay && month == monthClosing) return true;

				dateClosing.setDate(closingDay);
				const weekDayClosingAccount = dateClosing.getDay();
				if (closeNextDayWeekend && (weekDayClosingAccount === 0 || weekDayClosingAccount === 6)) {
					switch (weekDayClosingAccount) {
						case 0:
							day = day + 2;
							break;
						case 6:
							day = day + 1;
							break;
					}
					if (day >= closingDay && month == monthClosing) return true;
				}
				return false;
			},

			changePage () {
				this.pagination.page = this.currentPage;
				this.findPaymentSchedule();
			},

			changeItensByPage () {
				this.pagination.page = 1;
				this.findPaymentSchedule();
			},

			limparModal () {
				this.schedulePaymentUpsert_idPaymentSchedule = null;
				this.schedulePaymentUpsert_comments = "";
				this.schedulePaymentUpsert_reference = "";
				this.schedulePaymentUpsert_idCategoryExpense = null;
				this.schedulePaymentUpsert_idAccountSchedule = null;
				this.schedulePaymentUpsert_date = new Date();
				this.schedulePaymentUpsert_amount = [];
				this.schedulePaymentUpsert_paymentDate = new Date();
				this.schedulePaymentUpsert_installments = 1;
				this.schedulePaymentUpsert_valor = 0;
				this.schedulePaymentUpsert_valor_string = "0,00";

				this.paymentExecuteDate = new Date();
				this.paymentExecuteAccount = null;
				this.paymentExecuteComments = "";
				this.accountPayBalance = "0,00";

				this.methods_pay = [];
				this.invalidFormMulti = this.havePermissionMultiAccountPay;

				this.insufficientBalance = false;
				this.dataPaymentsInstallments = [];
				this.insufficientBalanceAccount = true;
				this.methodsPayAmount = 0;
				this.balanceZero = false;
				this.invalidFormMulti = true;
			},

			fecharModal (modal) {
				this.$refs[modal].hide();
				this.limparModal();
			},

			async setInstallments (value) {
				if (value) {
					await this.updateValue(value);
				}

				this.schedulePaymentUpsert_valor = this.schedulePaymentUpsert_valor || 0;
				this.schedulePaymentUpsert_installments = this.schedulePaymentUpsert_installments || 0;
				this.schedulePaymentUpsert_amount = [];
				this.schedulePaymentUpsert_amount_string = [];
				let valorParcela = parseFloat((this.schedulePaymentUpsert_valor / this.schedulePaymentUpsert_installments).toFixed(2));
				let somaTotal = 0;
				this.dataPaymentsInstallments = [];

				this.dataPaymentsInstallments.push(dayjs(this.schedulePaymentUpsert_paymentDate).locale("pt-br").format("DD-MM-YYYY"));
				const dateNextPayment = new Date(this.schedulePaymentUpsert_paymentDate);

				for (let parcela = 1 ; parcela <= parseInt(this.schedulePaymentUpsert_installments) ; parcela++) {
					if (parcela === parseInt(this.schedulePaymentUpsert_installments))
						valorParcela = parseFloat((this.schedulePaymentUpsert_valor) - somaTotal).toFixed(2);

					somaTotal = somaTotal + valorParcela;
					this.schedulePaymentUpsert_amount.push(valorParcela);
					this.schedulePaymentUpsert_amount_string.push(numberFormatted(valorParcela).replace(/\./g, ""));

					if (parcela > 1)
						dateNextPayment.setMonth(dateNextPayment.getMonth() + 1);

					this.dataPaymentsInstallments.push(dayjs(dateNextPayment).locale("pt-br").format("DD-MM-YYYY"));
				}
				this.getAccountPayBalance(this.schedulePaymentUpsert_idAccountSchedule);
			},

			setNewValue (amount, index) {
				if (amount) {
					this.schedulePaymentUpsert_amount_string[index] = validateNumber(amount);
					this.schedulePaymentUpsert_amount[index] = this.schedulePaymentUpsert_amount_string[index].replace(",", ".");
				} else {
					return;
				}

				let somaTotal = 0;
				this.schedulePaymentUpsert_amount.forEach(amount => {
					const valorParcela = parseFloat(amount);

					somaTotal = somaTotal + valorParcela;
				});
				this.schedulePaymentUpsert_valor = somaTotal.toFixed(2);
				this.schedulePaymentUpsert_valor_string = numberFormatted(this.schedulePaymentUpsert_valor).replace(/\./g, "");
			},

			setFilterDateEnd (date) {
				this.fim = getDateMonthEnd(date);
			},

			setFilter () {
				this.filters = {
					startDate: dayjs(this.inicio).locale("pt-br").format("YYYY-MM-DD"), // this.inicio,
					endDate: dayjs(this.fim).locale("pt-br").format("YYYY-MM-DD"), //this.fim,
					idAccount: this.selectedAccount || null,
					idAccountClass: this.selectedAccountClass || null,
					idCategoryExpense: this.selectedCategoryExpenses || null,
					statusPaymentSchedule: this.selectedStatusPaymentSchedule || null,
					dataPesquisa: this.selectedDataPesquisa || null,
					comments: this.comments,
					reference: this.reference
				};

				const fim = new Date();
				const month = fim.getMonth();
				const year = fim.getFullYear();
				const ultimoDiaMes = new Date(year, month + 1, 0).getDate();
				fim.setDate(ultimoDiaMes);
				const inicio = new Date();
				inicio.setDate("1");
				this.inicio = inicio;
				this.fim = fim;
			},

			async findAccount () {
				const result = await this.accountService.listAccount({offset: 0, limit: null}) || {};
				this.selectAccount = result.rows?.map(item => ({ nome: item.description, value: item.idAccount })) || [];
				this.optionModalAccount = result.rows?.map(item => ({
					text: item.description,
					value: item.idAccount,
					permiteParcelas: item.accountClass.closeMonthly,
					permitePagamento: item.accountClass.automaticPay,
					closingDay: item.closingDay,
					payDay: item.payDay,
					closeNextDayWeekend: item.closeNextDayWeekend,
					balance: item.balance
				})) || [];
			},

			async findAccountClass () {
				const result = await this.accountClassService.listAccountClass({offset: 0, limit: null}) || {};
				this.selectAccountClass = result.rows?.map(item => ({ nome: item.description, value: item.idAccountClass })) || [];
			},

			async findCategoryExpenses () {
				const result = await this.categoryExpensesService.listCategoryExpenses({offset: 0, limit: null}) || {};
				this.selectCategoryExpenses = result.rows?.map(item => ({ nome: item.description, value: item.idCategoryExpense })) || [];
				this.optionModalCategoryExpense = result.rows?.map(item => ({ text: item.description, value: item.idCategoryExpense })) || [];
			},

			async findPaymentSchedule (newFind) {
				if (newFind) {
					const exit = this.currentPage !== 1;
					this.pagination.page = 1;
					this.currentPage = 1;
					if (exit) return;
				}

				const limit = this.pagination.limit.value;
				this.pagination.offset = (this.pagination.page - 1) * limit;
				const offset = this.pagination.offset;
				const sortTable = this.sortTable;

				const filters = {
					limit,
					offset,
					initDate: dayjs(this.inicio).locale("pt-br").format("YYYY-MM-DD"),
					endDate: dayjs(this.fim).locale("pt-br").format("YYYY-MM-DD"),
					idAccount: this.selectedAccount,
					idAccountClass: this.selectedAccountClass,
					idCategoryExpense: this.selectedCategoryExpenses,
					statusPaymentSchedule: this.selectedStatusPaymentSchedule,
					dataPesquisa: this.selectedDataPesquisa,
					comments: this.comments,
					reference: this.reference,
					sortTable,
					extendedTable: true
				};

				this.loading = true;
				const result = await this.paymentScheduleService.getPaymentScheduleByParams(filters) || {};
				this.paymentSchedule = result.rows;
				this.linhas = result.rows.reduce((acc, c) => [
					...acc,
					{
						idPaymentSchedule: c.idPaymentSchedule,
						selected: false,
						notItemActions: Boolean(c.paymentedDate),
						notItemSelect: Boolean(c.cancelSchedule || c.paymentedDate),
						isCanceled: c.cancelSchedule,
						cols: [
							c.reference || "",
							(this.optionModalAccount.filter(tc => tc.value === c.idAccountSchedule))[0].text || "",
							(this.optionModalCategoryExpense.filter(tc => tc.value === c.idCategoryExpense))[0].text || "",
							c.cancelSchedule ? "Cancelada" : c.paymentedDate ? "Pagada" : "Em Aberto",
							formatterSimple(c.date),
							formatterSimple(c.paymentDate),
							numberFormatted(c.amount),
							c.comments
						]
					}
				], []);
				this.pagination.count = result.count || 0;
				this.pagination.pages = this.pagination.count > limit ? Math.ceil(this.pagination.count / limit) : 1;
				this.loading = false;
				this.selectItem = this.linhas.some(registro => registro.cols.some(status => status === "Em Aberto"));
				this.acoes = this.linhas.some(registro => registro.cols.some(status => status === "Em Aberto" || status === "Cancelada"));

				if (this.showFiltersForm)
					this.showFiltersForm = false;
			},

			formatter (date) {
				return dayjs(date).locale("pt-br").format("D [de] MMMM [de] YYYY");
			},

			validateState (ref) {
				this.invalidForm = hasFieldsWithErrors(this.veeFields);
				if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated))
					return !this.veeErrors.has(ref);

				return null;
			},

			async showSchedule (item_) {
				let item = {};
				if (item_.idPaymentSchedule)
					[ item ] = this.paymentSchedule.filter(c => c.idPaymentSchedule === item_.idPaymentSchedule);

				if (item.cancelSchedule || item.paymentedDate) return;

				const {
					amount,
					comments,
					date,
					idAccountSchedule,
					idCategoryExpense,
					idPaymentSchedule,
					paymentDate,
					reference
				} = item;

				if (!idPaymentSchedule) {
					this.titleModalSchedule = "Adicionar Agendamento";
					this.editing = false;
				} else {
					this.titleModalSchedule = "Editar Agendamento";
					this.editing = true;

					this.schedulePaymentUpsert_idPaymentSchedule = idPaymentSchedule;
					this.schedulePaymentUpsert_idAccountSchedule = idAccountSchedule || "";
					this.schedulePaymentUpsert_comments = comments;
					this.schedulePaymentUpsert_reference = reference;
					this.schedulePaymentUpsert_date = stringToDateUTC(date);
					this.schedulePaymentUpsert_idCategoryExpense = idCategoryExpense;
					this.schedulePaymentUpsert_paymentDate = stringToDateUTC(paymentDate);
					this.schedulePaymentUpsert_amount = [amount];
					this.schedulePaymentUpsert_amount_string = [amount.replace(".", ",")];
					this.schedulePaymentUpsert_valor = amount;
					this.schedulePaymentUpsert_valor_string = amount.replace(".", ",");
				}
				this.invalidForm = true;
				this.$refs.modalSchedule.show();
			},

			validateAmount (payload) {
				if (payload.installments < 2)
					return true;

				const valorTotal = parseFloat(payload.valor);
				const parcelasTotal = parseFloat(parseFloat(payload.amount.reduce((soma, parcela) => parseFloat(soma) + parseFloat(parcela), 0)).toFixed(2));

				if (valorTotal !== parcelasTotal)
					return false;

				return true;
			},

			async save () {
				const payload = {
					comments: this.schedulePaymentUpsert_comments,
					reference: this.schedulePaymentUpsert_reference,
					idCategoryExpense: this.schedulePaymentUpsert_idCategoryExpense,
					idAccountSchedule: this.schedulePaymentUpsert_idAccountSchedule,
					date: this.schedulePaymentUpsert_date,
					amount: this.schedulePaymentUpsert_amount,
					paymentDate: this.schedulePaymentUpsert_paymentDate,
					installments: this.schedulePaymentUpsert_installments,
					valor: this.schedulePaymentUpsert_valor,
					automaticPay: this.permitePagamento,
					idPaymentSchedule: this.schedulePaymentUpsert_idPaymentSchedule
				};

				const validateParcelas = this.validateAmount(payload);

				if (!payload.idCategoryExpense ||
					!payload.idAccountSchedule ||
					!payload.amount.length ||
					!payload.installments || !validateParcelas)
				{
					const messagem = "Verifique se os seguintes campos estão preenchidos corretamente: ";
					let messagem_1 = !payload.idCategoryExpense ? "- Categoria da Transação. " : "";
					messagem_1 = messagem_1.concat(!payload.idAccountSchedule ? "- Conta. " : "");
					messagem_1 = messagem_1.concat(!payload.amount.length || !payload.installments ? "- Parcela a agendar. " : "");
					messagem_1 = messagem_1.concat(!validateParcelas ? "- Valor das parcelas. " : "");

					await this.$swal({
						title: "Salvar",
						text: messagem.concat(messagem_1),
						type: "error",
						showCancelButton: false,
						//imageWidth: 50,
						//imageHeight: 50,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					this.invalidForm = true;
					return;
				}

				this.$snotify.async("Aguarde...", "Salvando", async () => {
					try {
						if (payload.idPaymentSchedule)
							await this.paymentScheduleService.updatePaymentSchedule({payload});
						else
							await this.paymentScheduleService.createPaymentSchedule({payload});

						this.fecharModal("modalSchedule");
						this.findPaymentSchedule();
						this.limparModal();
						this.findAccount();
						this.itemSelected = false;

						return {
							title: "Sucesso!",
							body: "Agendamento salvo com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar salvar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config: this.config
						};
					}
				});
			},

			async confirmaDelete (item) {
				this.confirmaApagar(item, true);
			},

			async confirmaCancel (item) {
				this.confirmaApagar(item, false);
			},

			async confirmaApagar (item_, deleteRecord) {

				if (typeof (item_) === "string") {
					this.apagarVarios();
					return;
				}

				const [ item ] = this.paymentSchedule.filter((gu) => gu.idPaymentSchedule === item_.idPaymentSchedule);
				if (item.cancelSchedule) return;

				if (item.paymentedDate) {
					await this.$swal({
						title: "Excluir",
						text: "Agendamento já baixado, não pôde ser deletado!",
						type: "error",
						showCancelButton: false,
						imageWidth: 50,
						imageHeight: 50,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					return;
				}

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja cancelar o agendamento?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (deleteConfirmation)
					this.apagar(item.idPaymentSchedule, deleteRecord);
			},

			async apagar (idPaymentSchedule, deleteRecord) {
				this.$snotify.async("Aguarde...", "Cancelando", async () => {
					try {
						const payload = { idsPaymentSchedule: [idPaymentSchedule], deleteRecord };
						await this.paymentScheduleService.deletePaymentSchedule({payload});
						this.findPaymentSchedule();
						this.itemSelected = false;

						return {
							title: "Sucesso!",
							body: "Agendamento Cancelado com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar cancelar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			AddDropDownActive () {
				this.showAddModal = !this.showAddModal;
			},

			async apagarVarios () {
				const idsPaymentSchedule = this.linhas.reduce((acc, item) => {
					if (item.selected)
						return [ ...acc, item.idPaymentSchedule ];

					return acc;
				}, []);

				if (!idsPaymentSchedule.length) return;

				const deleteConfirmation = (await this.$swal({
					title: "Excluir",
					text: "Tem certeza que deseja cancelar os agendamentos selecionados?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!deleteConfirmation)
					return;

				this.$snotify.async("Aguarde...", "Cancelando", async () => {
					try {
						const payload = { idsPaymentSchedule };
						await this.paymentScheduleService.deletePaymentSchedule({payload});
						this.findPaymentSchedule();
						this.itemSelected = false;

						return {
							title: "Sucesso!",
							body: "Agendamento cancelado com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar cancelar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			showPayment (item_) {
				this.limparModal();
				if (item_.notItemActions) return;

				let paymentSchedule = [];
				if (typeof item_ === "string") {
					paymentSchedule = this.linhas.reduce((acc, item) => {
						if (item.selected) {
							return [ ...acc, { idPaymentSchedule: item.idPaymentSchedule, valor: stringToNumber(item.cols[6]) }];
						}
						return acc;
					}, []);
					if (!paymentSchedule.length) return;
				} else {
					paymentSchedule = [{ idPaymentSchedule: item_.idPaymentSchedule, valor: stringToNumber(item_.cols[6]) }];
				}
				let valorTotal = 0;
				paymentSchedule.forEach(item => valorTotal = valorTotal + item.valor);
				this.paymentScheduleExecute = paymentSchedule.map(item => item);
				this.paymentExecuteAmount = parseFloat(parseFloat(valorTotal).toFixed(2));
				this.paymentExecuteAmount_string = numberFormatted(valorTotal);

				this.invalidForm = true;
				this.$refs.modalPayment.show();

			},

			async paymentExecute () {
				const baixaConfirmation = (await this.$swal({
					title: "Baixa",
					text: "Tem certeza que deseja executar a baixa?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (!baixaConfirmation)
					return;

				const payload = {
					idsPaymentSchedule: this.paymentScheduleExecute,
					paymentedDate: this.paymentExecuteDate,
					comments: this.paymentExecuteComments,
					methodsPay: this.methods_pay
				};

				this.$snotify.async("Aguarde...", "Executando baixas", async () => {
					try {
						await this.paymentScheduleService.executePayments({payload});

						this.fecharModal("modalPayment");
						this.findPaymentSchedule();
						this.limparModal();
						this.findAccount();
						this.itemSelected = false;

						return {
							title: "Sucesso!",
							body: "Baixas realizadas com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar executar baixas.";
						throw {
							title: "Falha!",
							body: msgError,
							config: this.config
						};
					}
				});
			},

			async updateValue (value) {
				this.schedulePaymentUpsert_valor_string = await validateNumber(value);
				this.schedulePaymentUpsert_valor = this.schedulePaymentUpsert_valor_string.replace(",", ".");
			},

			getAccountPayBalance (idAccount) {
				const accountHavePay = idAccount ? this.optionModalAccount.filter(account => account.value === idAccount)[0].permitePagamento : null;
				if (!accountHavePay) {
					this.insufficientBalanceAccount = false;
					this.insufficientBalance = false;
					this.accountPayBalance = "0,00";
					return;
				}

				const accountBalance = idAccount ? this.optionModalAccount.filter(account => account.value === idAccount)[0].balance : null;
				this.accountPayBalance = accountBalance ? numberFormatted(accountBalance) : "0,00";
				this.balanceZero = this.paymentExecuteAmount === this.methodsPayAmount;

				if (parseFloat(accountBalance) > 0)
					this.insufficientBalanceAccount = false;
				else
					this.insufficientBalanceAccount = true;

				if ((this.paymentExecuteAmount != 0 && this.paymentExecuteAmount > parseFloat(accountBalance)) || (this.schedulePaymentUpsert_valor != 0 && this.schedulePaymentUpsert_valor > parseFloat(accountBalance)))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

			},

			showButonsTop () {
				this.itemSelected = this.linhas.some(linha => linha.selected);
			},

			async toRecover (item_) {
				const [ item ] = this.paymentSchedule.filter((gu) => gu.idPaymentSchedule === item_.idPaymentSchedule);
				if (!item.cancelSchedule) return;

				if (item.paymentedDate) {
					await this.$swal({
						title: "Recuperar",
						text: "Agendamento já baixado, não pôde ser recuperado!",
						type: "error",
						showCancelButton: false,
						imageWidth: 50,
						imageHeight: 50,
						confirmButtonColor: "rgb(132, 170, 194)",
						confirmButtonText: "Ok"
					});
					return;
				}

				const recoverConfirmation = (await this.$swal({
					title: "Recuperar",
					text: "Tem certeza que deseja recuperar o agendamento?",
					type: "error",
					showCancelButton: true,
					cancelButtonColor: "#A6A8AB",
					confirmButtonColor: "#DD6B55",
					imageWidth: 50,
					imageHeight: 50,
					cancelButtonText: "Não",
					confirmButtonText: "Sim",
					reverseButtons: true
				})).value;

				if (recoverConfirmation)
					this.recover(item.idPaymentSchedule);

			},

			async recover (idPaymentSchedule) {
				this.$snotify.async("Aguarde...", "Recuperando", async () => {
					try {
						const payload = { idsPaymentSchedule: [idPaymentSchedule] };
						await this.paymentScheduleService.recoverPaymentSchedule({payload});
						this.findPaymentSchedule();

						return {
							title: "Sucesso!",
							body: "Agendamento recuperado com sucesso.",
							config: this.config
						};
					} catch (error) {
						const msgError = error.response.data.message || "Erro ao tentar recuperar o agendamento!";
						throw {
							title: "Falha!",
							body: msgError,
							config
						};
					}
				});
			},

			addMethotdPay (idAccount) {
				const accountSelectBalance = parseFloat(this.optionModalAccount.find(ele => ele.value === idAccount)?.balance || "0.00");
				this.paymentExecuteAccount = null;
				this.accountPayBalance = "0,00";

				if (this.methods_pay.length && this.methods_pay.some(ele => ele.account.idAccount === idAccount))
					return;

				const payAmountTotal = this.paymentExecuteAmount;
				let payAmountMethod = payAmountTotal - this.methodsPayAmount;
				const description = (this.optionModalAccount.filter(ele => ele.value === idAccount))[0].text;

				if (payAmountMethod > accountSelectBalance)
					payAmountMethod = accountSelectBalance;

				this.methods_pay.push({
					account: { idAccount, description },
					amount_string: numberFormatted(payAmountMethod),
					amount: payAmountMethod
				});
				this.methodsPayAmount = this.methodsPayAmount + payAmountMethod;
				this.balanceZero = this.paymentExecuteAmount === this.methodsPayAmount;

				if (this.paymentExecuteAmount != parseFloat(this.methodsPayAmount))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

				this.invalidFormMulti = this.insufficientBalance || !this.methods_pay.length;
			},

			delMethodPay (index) {
				this.methods_pay.splice(index, 1);
				this.methodsPayAmount = 0;
				this.methods_pay.forEach(ele => {
					this.methodsPayAmount = this.methodsPayAmount + ele.amount ;
				});

				if (this.paymentExecuteAmount != parseFloat(this.methodsPayAmount))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

				this.invalidFormMulti = this.insufficientBalance || !this.methods_pay.length;
			},

			setNewValueMethodPay (amount, index) {
				const idAccount = this.methods_pay[index].account.idAccount;
				const accountSelectBalance = parseFloat(this.optionModalAccount.find(ele => ele.value === idAccount)?.balance || "0.00");
				let totalPay = parseFloat((this.methods_pay.reduce((total, ele, idx) => {
					if (index === idx)
						return total;

					return total + ele.amount; }, 0)).toFixed(2)
				);

				if (amount) {
					const amountMethod = parseFloat(amount.replace(",", "."));
					totalPay += amountMethod;

					if (amountMethod > accountSelectBalance) {
						amount = accountSelectBalance.toString().replace(".", ",");
					} else {
						if (totalPay > this.paymentExecuteAmount) {
							totalPay = (totalPay - amountMethod).toFixed(2);
							amount = (this.paymentExecuteAmount - totalPay).toFixed(2).toString().replace(".", ",");
						}
					}

					this.methods_pay[index].amount_string = validateNumber(amount);
					this.methods_pay[index].amount = parseFloat(this.methods_pay[index].amount_string.replace(",", "."));
				} else {
					return;
				}

				this.methodsPayAmount = 0;
				this.methods_pay.forEach(ele => {
					this.methodsPayAmount = this.methodsPayAmount + ele.amount;
				});

				this.balanceZero = this.paymentExecuteAmount === this.methodsPayAmount || this.paymentExecuteAmount < this.methodsPayAmount;
				if (this.paymentExecuteAmount != parseFloat(this.methodsPayAmount))
					this.insufficientBalance = true;
				else
					this.insufficientBalance = false;

				this.invalidFormMulti = this.insufficientBalance || !this.methods_pay.length;
			},

			async updateValueMethodPay (value) {
				this.methods_pay.amount_string = await validateNumber(value);
				this.methods_pay.amount = this.methods_pay.amount_string.replace(",", ".");
			}
		}
	};
</script>

